import React from "react";
import { graphql } from "gatsby";
import moment from "moment";

import Container from "../../components/Container";
import ArticleBox from "../../components/ArticleBox";

// markup
const Retreats = ({ data }) => {
  const posts = data.allWpPost.nodes;

  return (
    <Container>
      <div className="d-flex flex-column">
        <h1 className="mb-3">Retreats</h1>
        <div className="row">
          {posts.map((post, index) => {
            const date = moment(post.date).format("D. MMMM YYYY");

            return (
              <div class="col-md-6">
                <ArticleBox
                  title={post.title}
                  excerpt={post.content.replace(/<[^>]*>?/gm, "")}
                  imageURL={post.featuredImage?.node?.sourceUrl}
                  date={date}
                  url={post.acf_post?.newsletterPdf?.mediaItemUrl || post.uri}
                  category={post.categories.nodes[0].name}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export const query = graphql`
  {
    allWpPost(
      filter: {
        status: { eq: "publish" }
        categories: { nodes: { elemMatch: { name: { eq: "Retreat" } } } }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        date
        featuredImage {
          node {
            sourceUrl
          }
        }
        acf_post {
          newsletterPdf {
            id
            mediaItemUrl
          }
        }
        categories {
          nodes {
            name
          }
        }
        link
        uri
        content
      }
    }
  }
`;

export default Retreats;
